<template>
  <div class="fill-height py-3 py-md-10">
    <Loading :loading="isLoading"></Loading>
    <v-container v-if="accessToken">
      <!--            <Divider long="100%"></Divider>-->
      <div class="mb-3 txt-head">
        MY BAG {{ inCart.length }} {{ inCart.length > 1 ? 'items' : 'item' }}
      </div>
      <v-layout mt-7 :column="$vuetify.breakpoint.smAndDown">
        <v-flex class="mb-5 mb-md-0">
          <CartTable
            :items="inCart"
            @increaseItem="increaseItem"
            @decreaseItem="decreaseItem"
            @removeItem="removeItem"
          ></CartTable>
          <div class="mt-3">
            <span
              class="pointer"
              @click="$router.push({ name: 'Home' })"
            >
              <v-icon>chevron_left</v-icon>
              <span class="text-decoration-underline">Continue Shopping</span>
            </span>
          </div>
        </v-flex>
        <Divider :vertical="true" v-if="$vuetify.breakpoint.mdAndUp"></Divider>
        <v-flex shrink lg3 style="min-width: 350px">
          <div class="txt-head darkblue-color mb-4">ORDER SUMMARY</div>
          <v-layout justify-space-between align-center mb-2>
            <v-flex shrink>
              {{ inCart.length }}
              {{ inCart.length > 1 ? 'items' : 'item' }}
            </v-flex>
            <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
              SGD {{ subTotal }}
            </v-flex>
          </v-layout>
          <v-layout justify-space-between align-center mb-2>
            <v-flex shrink>Total Product Cost :</v-flex>
            <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
              SGD {{ subTotal }}
            </v-flex>
          </v-layout>
          <v-layout justify-space-between align-center mb-5>
            <v-flex shrink>Shipping Cost :</v-flex>
            <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
              {{ shippingCost != 0 ? 'SGD ' + formatNumber(shippingCost, 2) : noAddressText }}
            </v-flex>
          </v-layout>
          <v-layout justify-space-between align-center>
            <v-flex shrink class="font-weight-bold">Total :</v-flex>
            <v-flex shrink class="font-weight-bold txt-head darkblue-color">
              SGD {{ totalIncludedTax }}
            </v-flex>
          </v-layout>
          <div class="mb-5">(Tax included)</div>
          <Button
            class="mb-5"
            type="dark"
            label="PROCESS TO CHECKOUT"
            :block="true"
            :disabled="inCart.length <= 0"
            @onClick="$router.push({ name: 'Checkout' })"
          ></Button>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Divider from '../components/Divider'
import Button from '../components/input/Button'
import CartTable from '../components/CartTable'
import { mapState } from 'vuex'
import { formatNumber } from "@/plugins/global-funcs"
import Loading from '../components/Loading'
import ServiceAPI from "@/services";

export default {
  name: 'Cart',
  components: {
    Divider,
    Button,
    CartTable,
    Loading
  },
  data() {
    return {
      isLoading: false,
      noAddressText: 'Calculate on next step',
      cartHeader: [
        {
          text: 'Product',
          value: 'product'
        },
        {
          text: 'Quantity',
          value: 'quantity',
          width: '140px',
          align: 'center'
        },
        {
          text: 'Total Price',
          value: 'total',
          width: '140px',
          align: 'center'
        },
        {
          text: '',
          value: 'remove',
          width: '60px',
          align: 'center'
        }
      ],
      deliveryCost: 5.0,
      formatNumber: formatNumber
    }
  },
  async created() {
    if (!this.accessToken) {
      this.$router.push({ name: 'Home' })
      return
    }
    this.isLoading = true
    let res = await ServiceAPI.address.getAddress({
      access_token: this.accessToken
    })

    if (res.status === 200 && res.data) {
      await this.$store.dispatch('Address/onGetShippingCost', {
        cart_id: this.cartDetail.id,
        address_id: res.data[0].delivery.id
      })
    }
    this.isLoading = false
  },
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      cartDetail: (state) => state.Cart.cartDetail,
      shippingCost: (state) => state.Address.shippingCost,
      accessToken: state => state.Auth.userDetail.access_token
    }),
    subTotal: function () {
      let sum = 0.0
      for (const x of this.inCart) {
        sum += parseFloat(x.price_per_piece * x.quantity, 2)
      }
      return parseFloat(sum) || parseFloat(sum) == 0
        ? formatNumber(sum, 2)
        : '-'
    },
    totalIncludedTax: function () {
      let total
      if (this.shippingCost == 0) {
        total = parseFloat(this.subTotal)
      } else {
        total = parseFloat(this.subTotal) + this.shippingCost
      }
      return parseFloat(total) || parseFloat(total) == 0
        ? formatNumber(total, 2)
        : '-'
    }
  },
  methods: {
    async increaseItem(product_stock_id) {
      let item = {
        access_token: this.accessToken,
        product_stock_id: product_stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/add', item)
      this.isLoading = false
    },
    async decreaseItem(product_stock_id) {
      let item = {
        access_token: this.accessToken,
        product_stock_id: product_stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/decreaseOne', item)
      this.isLoading = false
    },
    async removeItem(product_stock_id) {
      let item = {
        access_token: this.accessToken,
        product_stock_id: product_stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/removeItem', item)
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.table-head-theme {
  border: solid 1px var(--bkk-darkblue) !important;
}
</style>
