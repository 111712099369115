var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.cartHeader,"items":_vm.items,"mobile-breakpoint":"0","hide-default-footer":_vm.items.length <= 5},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('v-layout',[_c('v-flex',{attrs:{"shrink":"","mr-3":""}},[_c('v-img',{attrs:{"max-width":"100px","src":item.product && item.product.photos && item.product.photos.length > 0 ? item.product.photos[0].image_url:require('../assets/img/no-image.png')}})],1),_c('v-flex',{attrs:{"shrink":""}},[_c('div',{staticClass:"text-start txt-bigger darkblue-color"},[_vm._v(" "+_vm._s(item.product && item.product.name ? item.product.name : '-')+" ")]),_c('div',{staticClass:"text-start darkblue-color txt-bigger font-weight-light"},[_vm._v(" size: "+_vm._s(item.size)+" ")])])],1)]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('MiniQuantitySelector',{attrs:{"value":item.quantity,"lock":_vm.lock,"quantity-exceed":item.product_stock && item.product_stock.stock ? item.quantity >= item.product_stock.stock : true},on:{"increase":function($event){return _vm.eventIncreaseItem(item.product_stock_id)},"decrease":function($event){return _vm.eventDecreaseItem(item.product_stock_id)}}})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("SGD "+_vm._s(_vm.formatNumber(item.total_price, 2)))])]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.eventRemoveItem(item.product_stock_id)}}},[_c('v-icon',[_vm._v("close")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }