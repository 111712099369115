<template>
  <v-data-table
    :headers="cartHeader"
    :items="items"
    mobile-breakpoint="0"
    :hide-default-footer="items.length <= 5"
  >
    <template v-slot:item.product="{ item }">
      <v-layout>
        <v-flex shrink mr-3>
          <v-img
              max-width="100px"
              :src="item.product && item.product.photos && item.product.photos.length > 0 ? item.product.photos[0].image_url:require('../assets/img/no-image.png')"
          ></v-img>
        </v-flex>
        <v-flex shrink>
          <div class="text-start txt-bigger darkblue-color">
            {{ item.product && item.product.name ? item.product.name : '-' }}
          </div>
          <div class="text-start darkblue-color txt-bigger font-weight-light">
            size: {{ item.size }}
          </div>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:item.quantity="{ item }">
      <MiniQuantitySelector
        :value="item.quantity"
        :lock="lock"
        :quantity-exceed="item.product_stock && item.product_stock.stock ? item.quantity >= item.product_stock.stock : true"
        @increase="eventIncreaseItem(item.product_stock_id)"
        @decrease="eventDecreaseItem(item.product_stock_id)"
      ></MiniQuantitySelector>
    </template>
    <template v-slot:item.total="{ item }">
      <div>SGD {{ formatNumber(item.total_price, 2) }}</div>
    </template>
    <template v-slot:item.remove="{ item }">
      <v-btn icon small @click="eventRemoveItem(item.product_stock_id)">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import MiniQuantitySelector from './input/MiniQuantitySelector'
import { formatNumber } from "@/plugins/global-funcs";

export default {
  name: 'CartTable',
  components: {
    MiniQuantitySelector
  },
  props: {
    items: {
      default: []
    },
    deletable: {
      default: true
    },
    lock: {
      default: false
    }
  },
  data() {
    return {
      cartHeader: this.deletable
        ? [
            {
              text: 'Product',
              value: 'product'
            },
            {
              text: 'Quantity',
              value: 'quantity',
              width: '140px',
              align: 'center'
            },
            {
              text: 'Total Price',
              value: 'total',
              width: '140px',
              align: 'center'
            },
            {
              text: '',
              value: 'remove',
              width: '60px',
              align: 'center'
            }
          ]
        : [
            {
              text: 'Product',
              value: 'product'
            },
            {
              text: 'Quantity',
              value: 'quantity',
              width: '140px',
              align: 'center'
            },
            {
              text: 'Total Price',
              value: 'total',
              width: '140px',
              align: 'center'
            }
          ],
      formatNumber: formatNumber
    }
  },
  methods: {
    eventIncreaseItem(product_stock_id) {
      this.$emit('increaseItem', product_stock_id)
    },
    eventDecreaseItem(product_stock_id) {
      this.$emit('decreaseItem', product_stock_id)
    },
    eventRemoveItem(product_stock_id) {
      this.$emit('removeItem', product_stock_id)
    }
  }
}
</script>

<style scoped></style>
